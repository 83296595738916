import { SaveStatus } from '@common/enum/learningPath';
import { CoreApiResponse } from '@common/type/CoreApiResponseTypes';
import { isApiResponseSuccess } from '@common/utility/http';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import category from 'src/api/category';
import { MainCourseCategoryDropdownOption } from 'src/types/learningPath';

import learningPathActions from './action';
import { moduleLearningPathAPI } from './api';
import {
  FetchModuleCertificateCourseListRequest,
  FetchModuleCertificateCourseListResponse,
  FetchLearningPathListRequestAction,
  HttpFetchLearningPathListApiResponse,
  DeleteLearningPathRequestAction,
  FetchModuleCertificateAllCourseListRequestAction,
  HttpFetchModuleCertificateAllCourseListApiResponse,
  SaveModuleCertificateCreateRequest,
  SaveModuleCertificateCreateResponse,
  HttpFetchInstructorDropdownApiResponse,
  ModuleCertificateCreateState,
  FetchLearningPathStudentListRequestAction,
  HttpFetchLearningPathStudentListResponse,
  SearchCertificateBySlugRequest,
  SearchCertificateBySlugResponse,
  UploadLearningPathImageResponse,
  PreviewCertificateRequest,
  PreviewCertificateResponse,
  HttpDeleteLearningPathApiResponse,
  UploadLearningPathImageRequest,
  LearningPathStatusRequest,
  FetchLearningPathCourseBundleListRequestAction,
  HttpFetchLearningPathCourseBundleListApiResponse,
  CreateLearningPathShareLogRequestAction,
  FetchLearningPathDetailRequestAction,
  FetchLearningPathDetailSeoRequestAction,
  HttpCreateLearningPathShareLogApiResponse,
  HttpFetchLearningPathDetailApiResponse,
  HttpFetchLearningPathDetailSeoApiResponse,
  FetchModuleCertificateRequestAction,
  FetchMyLearningPathRequestAction,
  HttpFetchLearningPathAPIResponse,
  HttpFetchMyLearningPathApiResponse,
  FetchInstructorDropdownRequestAction,
} from './type';

import { filterCourseCategory } from '@helpers/moduleCertificateUtility/filterCourseCategory';
import { mapModuleCertificateCreateResponseToState } from '@helpers/moduleCertificateUtility/mapperModuleCertificate';

const {
  fetchLearningPathListAction,
  fetchModuleCertificateMainCourseCategoryDropdownAction,
  fetchModuleCertificateCourseListAction,
  fetchModuleCertificateAllCourseListAction,
  moduleCertificateCreateSaveDraftAction,
  moduleCertificateSavePublishAction,
  updateModuleCertificateCreateAction,
  updateCacheModuleCertificateCreateAction,
  fetchModuleCertificateInstructorDropdownAction,
  fetchLearningPathStudentListAction,
  moduleCertificateSearchSlugNameAction,
  uploadLearningPathCardAction,
  uploadLearningPathBannerCoverAction,
  uploadLearningPathLineImageAction,
  uploadLearningPathFacebookImageAction,
  moduleCertificateCreatePreviewCertificateAction,
  deleteLearningPathAction,
  fetchLearningPathSectionItemListAction,
  moduleCertificateViewDetailsChangeStatusAction,
  fetchLearningPathCourseBundleListAction,
  fetchLearningPathDetailAction,
  fetchLearningPathDetailSeoAction,
  createLearningPathShareLogAction,
  fetchModuleCertificateAction,
  fetchMyLearningPathAction,
} = learningPathActions;

function* fetchLearningPathListSaga(action: {
  type: typeof learningPathActions;
  payload: FetchLearningPathListRequestAction;
}) {
  const apiResponse: HttpFetchLearningPathListApiResponse = yield call(
    moduleLearningPathAPI.fetchLearningPathList,
    action.payload,
  );

  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(
      fetchLearningPathListAction.success({
        ...apiResponse.data,
      }),
    );
  } else {
    yield put(fetchLearningPathListAction.error(apiResponse));
  }
}

function* fetchModuleCertificateCategoryListSaga() {
  const apiResponse: CoreApiResponse<MainCourseCategoryDropdownOption[], string> = yield call(
    category.fetchCategories,
    {
      includeSubcategory: true,
    },
  );
  if (apiResponse) {
    yield put(fetchModuleCertificateMainCourseCategoryDropdownAction.success(filterCourseCategory(apiResponse.data)));
  } else {
    yield put(fetchModuleCertificateMainCourseCategoryDropdownAction.error(apiResponse));
  }
}

function* fetchModuleCertificateCourseCategoryListSaga(action: {
  type: typeof learningPathActions;
  payload: FetchModuleCertificateCourseListRequest;
}) {
  const apiResponse: FetchModuleCertificateCourseListResponse = yield call(
    moduleLearningPathAPI.fetchModuleCertificateCourseList,
    action.payload,
  );

  if (apiResponse) {
    yield put(fetchModuleCertificateCourseListAction.success(apiResponse));
  } else {
    yield put(fetchModuleCertificateCourseListAction.error(apiResponse));
  }
}

function* deleteLearningPathSaga(action: {
  type: typeof learningPathActions;
  payload: DeleteLearningPathRequestAction;
}) {
  const apiResponse: HttpDeleteLearningPathApiResponse = yield call(
    moduleLearningPathAPI.deleteLearningPath,
    action.payload,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(deleteLearningPathAction.success({}));
    if (action.payload.onSuccess) {
      action.payload.onSuccess();
    }
  } else {
    yield put(deleteLearningPathAction.error(apiResponse));
  }
}

function* fetchModuleCertificateAllCourseListSaga(action: {
  type: typeof learningPathActions;
  payload: FetchModuleCertificateAllCourseListRequestAction;
}) {
  const apiResponse: HttpFetchModuleCertificateAllCourseListApiResponse = yield call(
    moduleLearningPathAPI.fetchModuleCertificateAllCourseList,
    action.payload,
  );
  if (apiResponse) {
    yield put(fetchModuleCertificateAllCourseListAction.success(apiResponse.data));
  } else {
    yield put(fetchModuleCertificateAllCourseListAction.error(apiResponse));
  }
}
function* saveDraftModuleCertificateCreateSaga(action: {
  type: typeof learningPathActions;
  payload: SaveModuleCertificateCreateRequest;
}) {
  try {
    const updateResponse: CoreApiResponse<SaveModuleCertificateCreateResponse, string> = yield call(
      action.payload.id ? moduleLearningPathAPI.updateLearningPath : moduleLearningPathAPI.createLearningPath,
      action.payload,
    );
    const updateId = updateResponse.data?.id ?? action.payload.id;
    if (isApiResponseSuccess(updateResponse.status)) {
      const fetchResponse: CoreApiResponse<ModuleCertificateCreateState, string> = yield call(
        moduleLearningPathAPI.fetchLearningPath,
        {
          id: updateId,
        },
      );
      yield put(
        updateModuleCertificateCreateAction.update({
          ...mapModuleCertificateCreateResponseToState(fetchResponse.data),
          learningPathNameCache: action.payload.name,
        }),
      );
      yield put(
        updateCacheModuleCertificateCreateAction.update(mapModuleCertificateCreateResponseToState(fetchResponse.data)),
      );
      yield put(
        moduleCertificateCreateSaveDraftAction.success(action.payload?.id ? SaveStatus.CHANGE : SaveStatus.DRAFT),
      );
    } else {
      yield put(moduleCertificateCreateSaveDraftAction.error(updateResponse.data));
    }
  } catch (error) {
    yield put(moduleCertificateCreateSaveDraftAction.error(error));
  }
}

function* savePublishModuleCertificateCreateSaga(action: {
  type: typeof learningPathActions;
  payload: SaveModuleCertificateCreateRequest;
}) {
  try {
    const updateResponse: CoreApiResponse<SaveModuleCertificateCreateResponse, string> = yield call(
      action.payload.id ? moduleLearningPathAPI.updateLearningPath : moduleLearningPathAPI.createLearningPath,
      action.payload,
    );
    const updateId = updateResponse.data?.id ?? action.payload.id;
    if (isApiResponseSuccess(updateResponse.status)) {
      const fetchResponse: CoreApiResponse<ModuleCertificateCreateState, string> = yield call(
        moduleLearningPathAPI.fetchLearningPath,
        {
          id: updateId,
        },
      );
      yield put(
        updateModuleCertificateCreateAction.update({
          ...mapModuleCertificateCreateResponseToState(fetchResponse.data),
          learningPathNameCache: action.payload.name,
        }),
      );
      yield put(
        updateCacheModuleCertificateCreateAction.update(mapModuleCertificateCreateResponseToState(fetchResponse.data)),
      );
      yield put(moduleCertificateSavePublishAction.success(SaveStatus.PUBLISH));
    } else {
      yield put(moduleCertificateSavePublishAction.error(updateResponse.data));
    }
  } catch (error) {
    yield put(moduleCertificateSavePublishAction.error(error));
  }
}
function* fetchModuleCertificateInstructorListSaga(action: {
  type: typeof learningPathActions;
  payload: FetchInstructorDropdownRequestAction;
}) {
  const apiResponse: HttpFetchInstructorDropdownApiResponse = yield call(
    moduleLearningPathAPI.fetchModuleCertificateInstructorDropdown,
    action.payload,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(
      fetchModuleCertificateInstructorDropdownAction.success(
        apiResponse.data?.items?.map((instructor) => ({
          id: instructor.userId ?? 0,
          fullName: instructor.fullName,
        })),
      ),
    );
  } else {
    yield put(fetchModuleCertificateInstructorDropdownAction.error(apiResponse));
  }
}

function* fetchLearningPathSaga(action: { type: typeof learningPathActions; payload: { id: number } }) {
  try {
    const apiResponse: CoreApiResponse<ModuleCertificateCreateState, string> = yield call(
      moduleLearningPathAPI.fetchLearningPath,
      action.payload,
    );
    if (isApiResponseSuccess(apiResponse.status)) {
      const moduleCertificateCreateState = mapModuleCertificateCreateResponseToState(apiResponse.data);
      yield put(updateModuleCertificateCreateAction.success(moduleCertificateCreateState));
      yield put(updateCacheModuleCertificateCreateAction.update(moduleCertificateCreateState));
    } else {
      yield put(updateModuleCertificateCreateAction.error(apiResponse));
    }
  } catch (error) {
    yield put(updateModuleCertificateCreateAction.error(error));
  }
}

function* fetchLearningPathStudentListSaga(action: {
  type: typeof learningPathActions;
  payload: FetchLearningPathStudentListRequestAction;
}) {
  try {
    const apiResponse: HttpFetchLearningPathStudentListResponse = yield call(
      moduleLearningPathAPI.fetchLearningPathStudentList,
      action.payload,
    );

    if (isApiResponseSuccess(apiResponse.status)) {
      yield put(
        fetchLearningPathStudentListAction.success({
          ...apiResponse.data,
        }),
      );
    } else {
      yield put(fetchLearningPathStudentListAction.error(apiResponse));
    }
  } catch (error) {
    yield put(fetchLearningPathStudentListAction.error(undefined));
  }
}

function* fetchCertificateBySlugName(action: {
  type: typeof moduleCertificateSearchSlugNameAction;
  payload: SearchCertificateBySlugRequest;
}) {
  try {
    const apiResponse: CoreApiResponse<SearchCertificateBySlugResponse, string> = yield call(
      moduleLearningPathAPI.fetchCertificateBySlugName,
      action.payload,
    );

    if (isApiResponseSuccess(apiResponse.status)) {
      yield put(
        updateModuleCertificateCreateAction.update({
          certificatePreviewImage: apiResponse.data.thumbnail,
        }),
      );
    } else {
      yield put(
        updateModuleCertificateCreateAction.update({
          certificatePreviewImage: undefined,
        }),
      );
    }
  } catch (error) {
    yield put(
      updateModuleCertificateCreateAction.update({
        certificatePreviewImage: undefined,
      }),
    );
  }
}

function* uploadLearningPathCardSaga(action: {
  type: typeof uploadLearningPathCardAction;
  payload: UploadLearningPathImageRequest;
}) {
  try {
    const apiResponse: CoreApiResponse<UploadLearningPathImageResponse, string> = yield call(
      moduleLearningPathAPI.uploadImage,
      action.payload,
    );

    if (isApiResponseSuccess(apiResponse.status)) {
      yield put(
        uploadLearningPathCardAction.success({
          url: apiResponse.data.url,
          key: apiResponse.data.key,
          primaryColor: apiResponse.data.primaryColor,
          secondaryColor: apiResponse.data.secondaryColor,
        }),
      );
    } else {
      yield put(uploadLearningPathCardAction.error(apiResponse));
    }
  } catch (error) {
    yield put(uploadLearningPathCardAction.error(error));
  }
}

function* uploadLearningPathBannerCoverSaga(action: {
  type: typeof uploadLearningPathBannerCoverAction;
  payload: UploadLearningPathImageRequest;
}) {
  try {
    const apiResponse: CoreApiResponse<UploadLearningPathImageResponse, string> = yield call(
      moduleLearningPathAPI.uploadImage,
      action.payload,
    );

    if (isApiResponseSuccess(apiResponse.status)) {
      yield put(
        uploadLearningPathBannerCoverAction.success({
          url: apiResponse.data.url,
          key: apiResponse.data.key,
        }),
      );
    } else {
      yield put(uploadLearningPathBannerCoverAction.error(apiResponse));
    }
  } catch (error) {
    yield put(uploadLearningPathBannerCoverAction.error(error));
  }
}

function* uploadLearningPathLineImageSaga(action: {
  type: typeof uploadLearningPathLineImageAction;
  payload: UploadLearningPathImageRequest;
}) {
  try {
    const apiResponse: CoreApiResponse<UploadLearningPathImageResponse, string> = yield call(
      moduleLearningPathAPI.uploadImage,
      action.payload,
    );

    if (isApiResponseSuccess(apiResponse.status)) {
      yield put(
        uploadLearningPathLineImageAction.success({
          url: apiResponse.data.url,
          key: apiResponse.data.key,
        }),
      );
    } else {
      yield put(uploadLearningPathLineImageAction.error(apiResponse));
    }
  } catch (error) {
    yield put(uploadLearningPathLineImageAction.error(error));
  }
}

function* uploadLearningPathFacebookImageSaga(action: {
  type: typeof uploadLearningPathFacebookImageAction;
  payload: UploadLearningPathImageRequest;
}) {
  try {
    const apiResponse: CoreApiResponse<UploadLearningPathImageResponse, string> = yield call(
      moduleLearningPathAPI.uploadImage,
      action.payload,
    );

    if (isApiResponseSuccess(apiResponse.status)) {
      yield put(
        uploadLearningPathFacebookImageAction.success({
          url: apiResponse.data.url,
          key: apiResponse.data.key,
        }),
      );
    } else {
      yield put(uploadLearningPathFacebookImageAction.error(apiResponse));
    }
  } catch (error) {
    yield put(uploadLearningPathFacebookImageAction.error(error));
  }
}

function* fetchPreviewCertificateSaga(action: {
  type: typeof moduleCertificateCreatePreviewCertificateAction;
  payload: PreviewCertificateRequest;
}) {
  try {
    const apiResponse: CoreApiResponse<PreviewCertificateResponse, string> = yield call(
      moduleLearningPathAPI.fetchPreviewCertificate,
      action.payload,
    );

    if (isApiResponseSuccess(apiResponse.status)) {
      yield put(moduleCertificateCreatePreviewCertificateAction.success(apiResponse.data.certificateImage));
    } else {
      yield put(moduleCertificateCreatePreviewCertificateAction.error(apiResponse));
    }
  } catch (error) {
    yield put(moduleCertificateCreatePreviewCertificateAction.error(error));
  }
}

function* searchLearningPathList(action: {
  type: typeof fetchLearningPathSectionItemListAction;
  payload: FetchLearningPathListRequestAction;
}) {
  try {
    const apiResponse: HttpFetchLearningPathListApiResponse = yield call(
      moduleLearningPathAPI.fetchLearningPathList,
      action.payload,
    );
    if (isApiResponseSuccess(apiResponse.status)) {
      yield put(fetchLearningPathSectionItemListAction.success(apiResponse.data.items));
    } else {
      yield put(fetchLearningPathSectionItemListAction.error(apiResponse));
    }
  } catch (error) {
    yield put(fetchLearningPathSectionItemListAction.error(error));
  }
}

function* updateLearningPathStatusSaga(action: {
  type: typeof moduleCertificateViewDetailsChangeStatusAction;
  payload: LearningPathStatusRequest;
}) {
  try {
    const updateResponse: CoreApiResponse<{ id: number }, string> = yield call(
      moduleLearningPathAPI.updateLearningPathStatus,
      action.payload,
    );
    const updateId = updateResponse.data?.id ?? action.payload.learningPathId;
    if (isApiResponseSuccess(updateResponse.status)) {
      const fetchResponse: CoreApiResponse<ModuleCertificateCreateState, string> = yield call(
        moduleLearningPathAPI.fetchLearningPath,
        {
          id: updateId,
        },
      );
      yield put(
        updateModuleCertificateCreateAction.update({
          ...mapModuleCertificateCreateResponseToState(fetchResponse.data),
        }),
      );
      yield put(
        updateCacheModuleCertificateCreateAction.update(mapModuleCertificateCreateResponseToState(fetchResponse.data)),
      );
      yield put(moduleCertificateViewDetailsChangeStatusAction.success());
    } else {
      yield put(moduleCertificateViewDetailsChangeStatusAction.error(updateResponse.data));
    }
  } catch (error) {
    yield put(moduleCertificateViewDetailsChangeStatusAction.error(error));
  }
}
function* fetchLearningPathCourseBundleListSaga(action: {
  type: typeof learningPathActions;
  payload: FetchLearningPathCourseBundleListRequestAction;
}) {
  const apiResponse: HttpFetchLearningPathCourseBundleListApiResponse = yield call(
    moduleLearningPathAPI.fetchLearningPathCourseBundleList,
    action.payload,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchLearningPathCourseBundleListAction.success(apiResponse.data));
  } else {
    yield put(fetchLearningPathCourseBundleListAction.error(apiResponse));
  }
}

function* fetchLearningPathDetailSaga(action: {
  type: typeof learningPathActions;
  payload: FetchLearningPathDetailRequestAction;
}) {
  const apiResponse: HttpFetchLearningPathDetailApiResponse = yield call(
    moduleLearningPathAPI.getLearningPathDetail,
    action.payload.learningPathId,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchLearningPathDetailAction.success(apiResponse.data));
  } else {
    yield put(fetchLearningPathDetailAction.error(apiResponse));
  }
}

function* fetchLearningPathSeoDetailSaga(action: {
  type: typeof learningPathActions;
  payload: FetchLearningPathDetailSeoRequestAction;
}) {
  const apiResponse: HttpFetchLearningPathDetailSeoApiResponse = yield call(
    moduleLearningPathAPI.getLearningPathDetailSeo,
    action.payload.learningPathId,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchLearningPathDetailSeoAction.success(apiResponse.data));
  } else {
    yield put(fetchLearningPathDetailSeoAction.error(apiResponse));
  }
}

function* createLearningPathShareLog(action: {
  type: typeof learningPathActions;
  payload: CreateLearningPathShareLogRequestAction;
}) {
  const apiResponse: HttpCreateLearningPathShareLogApiResponse = yield call(
    moduleLearningPathAPI.createLearningPathShareLog,
    action.payload,
  );
  if (!isApiResponseSuccess(apiResponse.status)) {
    yield put(createLearningPathShareLogAction.error(apiResponse));
  }
}

function* fetchCertificateBundleSaga(action: {
  type: typeof learningPathActions;
  payload: FetchModuleCertificateRequestAction;
}) {
  const apiResponse: HttpFetchLearningPathAPIResponse = yield call(
    moduleLearningPathAPI.getMyModuleCertificate,
    action.payload.id,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchModuleCertificateAction.success(apiResponse.data));
  } else {
    yield put(fetchModuleCertificateAction.error(apiResponse));
  }
}

function* fetchMyLearningPathSaga(action: {
  type: typeof learningPathActions;
  payload: FetchMyLearningPathRequestAction;
}) {
  const apiResponse: HttpFetchMyLearningPathApiResponse = yield call(
    moduleLearningPathAPI.getMyLearningPath,
    action.payload,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchMyLearningPathAction.success(apiResponse.data));
  } else {
    yield put(fetchMyLearningPathAction.error(apiResponse));
  }
}

export default function* moduleCertificateSaga() {
  yield all([
    takeEvery(learningPathActions.FETCH_LEARNING_PATH_LIST_REQUEST, fetchLearningPathListSaga),
    takeEvery(
      learningPathActions.LEARNING_PATH_MAIN_COURSE_CATEGORY_DROPDOWN_REQUEST,
      fetchModuleCertificateCategoryListSaga,
    ),
    takeEvery(
      learningPathActions.FETCH_LEARNING_PATH_COURSE_LIST_REQUEST,
      fetchModuleCertificateCourseCategoryListSaga,
    ),
    takeEvery(learningPathActions.DELETE_LEARNING_PATH_REQUEST, deleteLearningPathSaga),
    takeEvery(learningPathActions.FETCH_LEARNING_PATH_ALL_COURSE_LIST_REQUEST, fetchModuleCertificateAllCourseListSaga),
    takeEvery(learningPathActions.LEARNING_PATH_CREATE_SAVE_DRAFT_REQUEST, saveDraftModuleCertificateCreateSaga),
    takeEvery(learningPathActions.LEARNING_PATH_INSTRUCTOR_DROPDOWN_REQUEST, fetchModuleCertificateInstructorListSaga),
    takeEvery(learningPathActions.LEARNING_PATH_CREATE_REQUEST, fetchLearningPathSaga),
    takeEvery(learningPathActions.FETCH_LEARNING_PATH_STUDENT_LIST_REQUEST, fetchLearningPathStudentListSaga),
    takeEvery(learningPathActions.LEARNING_PATH_SEARCH_SLUG_NAME_REQUEST, fetchCertificateBySlugName),
    takeEvery(learningPathActions.LEARNING_PATH_SAVE_PUBLISH_REQUEST, savePublishModuleCertificateCreateSaga),
    takeEvery(learningPathActions.UPLOAD_LEARNING_PATH_CARD_REQUEST, uploadLearningPathCardSaga),
    takeEvery(learningPathActions.UPLOAD_LEARNING_PATH_BANNER_COVER_REQUEST, uploadLearningPathBannerCoverSaga),
    takeEvery(learningPathActions.UPLOAD_LEARNING_PATH_LINE_IMAGE_REQUEST, uploadLearningPathLineImageSaga),
    takeEvery(learningPathActions.UPLOAD_LEARNING_PATH_FACEBOOK_IMAGE_REQUEST, uploadLearningPathFacebookImageSaga),
    takeEvery(learningPathActions.LEARNING_PATH_CREATE_PREVIEW_CERTIFICATE_REQUEST, fetchPreviewCertificateSaga),
    takeEvery(learningPathActions.LEARNING_PATH_SECTION_ITEM_LIST_REQUEST, searchLearningPathList),
    takeEvery(learningPathActions.LEARNING_PATH_VIEW_DETAILS_CHANGE_STATUS_REQUEST, updateLearningPathStatusSaga),
    takeEvery(
      learningPathActions.FETCH_LEARNING_PATH_COURSE_BUNDLE_LIST_REQUEST,
      fetchLearningPathCourseBundleListSaga,
    ),
    takeEvery(learningPathActions.FETCH_LEARNING_PATH_DETAIL_REQUEST, fetchLearningPathDetailSaga),
    takeEvery(learningPathActions.FETCH_LEARNING_PATH_DETAIL_SEO_REQUEST, fetchLearningPathSeoDetailSaga),
    takeEvery(learningPathActions.CREATE_LEARNING_PATH_SHARE_LOG_REQUEST, createLearningPathShareLog),
    takeEvery(learningPathActions.FETCH_MODULE_CERTIFICATE_REQUEST, fetchCertificateBundleSaga),
    takeEvery(learningPathActions.FETCH_MY_LEARNING_PATH_REQUEST, fetchMyLearningPathSaga),
  ]);
}
