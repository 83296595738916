import { CertificateType, LearningPathStatus } from '@common/enum/learningPath';

import { uniqueId } from 'lodash';
import { ModuleCertificateCreateErrorState, ModuleCertificateCreateState } from '@redux/learningPath/type';
import {
  CategorySelectListType,
  CourseCategoryDialogError,
  CourseSetType,
  LearningPathStep,
} from 'src/types/learningPath';

export const defaultCategorySelect: CategorySelectListType = {
  id: '0',
  parentCategory: {
    id: 0,
    name: '',
    enabled: false,
  },
  category: {
    id: -1,
    name: '',
    enabled: false,
  },
  certificateBundleCondition: undefined,
  requiredCondition: '',
  courseList: [],
  someCourseInactive: false,
};

export const defaultCourseSet: CourseSetType = {
  id: uniqueId('courseSet_'),
  certificateType: CertificateType.COURSE,
  courseSelectList: [],
  categorySelectList: [],
  courseCertificateBundleCondition: undefined,
  categoryCertificateBundleCondition: undefined,
  requiredCondition: '',
};

export const defaultLearningPathStep: LearningPathStep = {
  id: uniqueId('learningPathStep_'),
  courseSet: [{ ...defaultCourseSet }],
  conditionRelation: undefined,
};

export const defaultModuleCertificateCreate: ModuleCertificateCreateState = {
  learningPathStatus: LearningPathStatus.DRAFT,
  learningPathName: '',
  slugName: '',
  certificatePreviewImage: undefined,
  description: '',
  learningPathStepList: [
    {
      ...defaultLearningPathStep,
    },
  ],
  numberCertificateInstructors: 0,
  certificateInstructors: [],
  bannerPrimaryColor: '#F3F3F3',
  bannerSecondaryColor: '#BDBDBD',
  customBannerPrimaryColor: '#F3F3F3',
  customBannerSecondaryColor: '#BDBDBD',
  isUseDefaultBannerColor: true,
};

export const defaultCourseCategoryDialogError: CourseCategoryDialogError = {
  parentCategory: undefined,
  category: undefined,
  bundleCondition: undefined,
  requiredCondition: undefined,
};

export const defaultModuleCertificateCreateErrorState: ModuleCertificateCreateErrorState = {
  learningPathName: undefined,
  slugName: undefined,
  learningPathStepMissingConditionSetting: [],
  duplicateLearningPathStepError: [],
  instructorErrorList: [],
};

export const DEFAULT_BANNER_COLOR = {
  PRIMARY: '#F3F3F3',
  SECONDARY: '#BDBDBD',
};
