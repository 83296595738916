import { defaultModuleCertificateCreate } from '@common/constant/learningPathConstant';
import { SaveStatus } from '@common/enum/learningPath';
import {
  InstructorDropdownOption,
  MainCourseCategoryDropdownOption,
  SubCourseCategoryDropdownOption,
} from 'src/types/learningPath';

import {
  FetchLearningPathListRequestAction,
  FetchLearningPathListErrorAction,
  FetchLearningPathListSuccessAction,
  ModuleCertificateCreateState,
  DeleteLearningPathRequestAction,
  DeleteLearningPathSuccessAction,
  DeleteLearningPathErrorAction,
  FetchModuleCertificateCourseListRequest,
  FetchLearningPathCourseBundleListRequestAction,
  FetchLearningPathCourseBundleListSuccessAction,
  ModuleCertificateCreateErrorState,
  PreviewCertificateRequest,
  FetchModuleCertificateCourseListResponse,
  SaveModuleCertificateCreateRequest,
  FetchLearningPathStudentListRequestAction,
  FetchLearningPathStudentListSuccessAction,
  FetchLearningPathStudentListErrorAction,
  SearchCertificateBySlugRequest,
  UploadLearningPathImageRequest,
  UploadLearningPathImageResponse,
  LearningPathEntity,
  LearningPathStatusRequest,
  FetchModuleCertificateAllCourseListRequestAction,
  FetchModuleCertificateAllCourseListSuccessAction,
  CreateLearningPathShareLogErrorAction,
  CreateLearningPathShareLogRequestAction,
  CreateLearningPathShareLogSuccessAction,
  FetchLearningPathDetailErrorAction,
  FetchLearningPathDetailRequestAction,
  FetchLearningPathDetailSeoErrorAction,
  FetchLearningPathDetailSeoRequestAction,
  FetchLearningPathDetailSeoSuccessAction,
  FetchLearningPathDetailSuccessAction,
  FetchModuleCertificateErrorAction,
  FetchModuleCertificateRequestAction,
  FetchModuleCertificateSuccessAction,
  FetchMyLearningPathErrorAction,
  FetchMyLearningPathRequestAction,
  FetchMyLearningPathSuccessAction,
  FetchInstructorDropdownRequestAction,
} from '@redux/learningPath/type';

const learningPathActions = {
  RESET_ALL_STATE: 'LEARNING_PATH/RESET_ALL_STATE',
  resetAllStateAction: () => ({
    type: learningPathActions.RESET_ALL_STATE,
  }),

  LEARNING_PATH_CREATE_SET_DEFAULT: 'LEARNING_PATH/LEARNING_PATH_CREATE_SET_DEFAULT',
  LEARNING_PATH_CREATE_REQUEST: 'LEARNING_PATH/LEARNING_PATH_CREATE_REQUEST',
  LEARNING_PATH_CREATE_SUCCESS: 'LEARNING_PATH/LEARNING_PATH_CREATE_SUCCESS',
  LEARNING_PATH_CREATE_UPDATE: 'LEARNING_PATH/LEARNING_PATH_CREATE_UPDATE',
  LEARNING_PATH_CREATE_REQUEST_ERROR: 'LEARNING_PATH/LEARNING_PATH_CREATE_REQUEST_ERROR',
  LEARNING_PATH_CREATE_RESET: 'LEARNING_PATH/LEARNING_PATH_CREATE_RESET',
  updateModuleCertificateCreateAction: {
    request: (payload: { id: number }) => ({
      type: learningPathActions.LEARNING_PATH_CREATE_REQUEST,
      payload,
    }),
    success: (payload: ModuleCertificateCreateState) => ({
      type: learningPathActions.LEARNING_PATH_CREATE_SUCCESS,
      payload,
    }),
    setDefault: () => ({
      type: learningPathActions.LEARNING_PATH_CREATE_SET_DEFAULT,
      payload: defaultModuleCertificateCreate,
    }),
    update: (payload: Partial<ModuleCertificateCreateState>) => ({
      type: learningPathActions.LEARNING_PATH_CREATE_UPDATE,
      payload,
    }),
    error: (error: unknown) => ({
      type: learningPathActions.LEARNING_PATH_CREATE_REQUEST_ERROR,
      error,
    }),
    reset: () => ({
      type: learningPathActions.LEARNING_PATH_CREATE_RESET,
    }),
  },

  CACHE_LEARNING_PATH_CREATE_UPDATE: 'LEARNING_PATH/CACHE_LEARNING_PATH_CREATE_UPDATE',
  updateCacheModuleCertificateCreateAction: {
    update: (payload: Partial<ModuleCertificateCreateState>) => ({
      type: learningPathActions.CACHE_LEARNING_PATH_CREATE_UPDATE,
      payload,
    }),
  },

  LEARNING_PATH_SLUG_DROPDOWN_REQUEST: 'LEARNING_PATH/LEARNING_PATH_SLUG_DROPDOWN_REQUEST',
  LEARNING_PATH_SLUG_DROPDOWN_SUCCESS: 'LEARNING_PATH/LEARNING_PATH_SLUG_DROPDOWN_SUCCESS',
  updateModuleCertificateSlugDropdownAction: {
    request: () => ({
      type: learningPathActions.LEARNING_PATH_SLUG_DROPDOWN_REQUEST,
    }),
    success: (payload: string[]) => ({
      type: learningPathActions.LEARNING_PATH_SLUG_DROPDOWN_SUCCESS,
      payload,
    }),
  },

  FETCH_LEARNING_PATH_LIST_REQUEST: 'LEARNING_PATH/FETCH_LEARNING_PATH_LIST_REQUEST',
  FETCH_LEARNING_PATH_LIST_SUCCESS: 'LEARNING_PATH/FETCH_LEARNING_PATH_LIST_SUCCESS',
  FETCH_LEARNING_PATH_LIST_ERROR: 'LEARNING_PATH/FETCH_LEARNING_PATH_LIST_ERROR',
  FETCH_LEARNING_PATH_LIST_RESET: 'LEARNING_PATH/FETCH_LEARNING_PATH_LIST_RESET',

  fetchLearningPathListAction: {
    request: (payload: FetchLearningPathListRequestAction) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_LIST_REQUEST,
      payload,
    }),
    success: (payload: FetchLearningPathListSuccessAction) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_LIST_SUCCESS,
      payload,
    }),
    error: (error: FetchLearningPathListErrorAction) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_LIST_ERROR,
      error,
    }),
    reset: () => ({
      type: learningPathActions.FETCH_LEARNING_PATH_LIST_RESET,
    }),
  },

  LEARNING_PATH_MAIN_COURSE_CATEGORY_DROPDOWN_REQUEST:
    'LEARNING_PATH/LEARNING_PATH_MAIN_COURSE_CATEGORY_DROPDOWN_REQUEST',
  LEARNING_PATH_MAIN_COURSE_CATEGORY_DROPDOWN_SUCCESS:
    'LEARNING_PATH/LEARNING_PATH_MAIN_COURSE_CATEGORY_DROPDOWN_SUCCESS',
  LEARNING_PATH_MAIN_COURSE_CATEGORY_DROPDOWN_ERROR: 'LEARNING_PATH/LEARNING_PATH_MAIN_COURSE_CATEGORY_DROPDOWN_ERROR',
  fetchModuleCertificateMainCourseCategoryDropdownAction: {
    request: () => ({
      type: learningPathActions.LEARNING_PATH_MAIN_COURSE_CATEGORY_DROPDOWN_REQUEST,
    }),
    success: (payload: MainCourseCategoryDropdownOption[]) => ({
      type: learningPathActions.LEARNING_PATH_MAIN_COURSE_CATEGORY_DROPDOWN_SUCCESS,
      payload,
    }),
    error: (error: unknown) => ({
      type: learningPathActions.LEARNING_PATH_MAIN_COURSE_CATEGORY_DROPDOWN_ERROR,
      error,
    }),
  },

  LEARNING_PATH_SUB_COURSE_CATEGORY_DROPDOWN_SUCCESS:
    'LEARNING_PATH/LEARNING_PATH_SUB_COURSE_CATEGORY_DROPDOWN_SUCCESS',
  updateModuleCertificateSubCourseCategoryDropdownAction: {
    success: (payload: SubCourseCategoryDropdownOption[]) => ({
      type: learningPathActions.LEARNING_PATH_SUB_COURSE_CATEGORY_DROPDOWN_SUCCESS,
      payload,
    }),
  },

  FETCH_LEARNING_PATH_COURSE_LIST_REQUEST: 'LEARNING_PATH/FETCH_LEARNING_PATH_COURSE_LIST_REQUEST',
  FETCH_LEARNING_PATH_COURSE_LIST_SUCCESS: 'LEARNING_PATH/FETCH_LEARNING_PATH_COURSE_LIST_SUCCESS',
  FETCH_LEARNING_PATH_COURSE_LIST_ERROR: 'LEARNING_PATH/FETCH_LEARNING_PATH_COURSE_LIST_ERROR',
  FETCH_LEARNING_PATH_COURSE_LIST_RESET: 'LEARNING_PATH/FETCH_LEARNING_PATH_COURSE_LIST_RESET',
  fetchModuleCertificateCourseListAction: {
    request: (payload: FetchModuleCertificateCourseListRequest) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_COURSE_LIST_REQUEST,
      payload,
    }),
    success: (payload: FetchModuleCertificateCourseListResponse) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_COURSE_LIST_SUCCESS,
      payload,
    }),
    error: (error: unknown) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_COURSE_LIST_ERROR,
      error,
    }),
    reset: () => ({
      type: learningPathActions.FETCH_LEARNING_PATH_COURSE_LIST_RESET,
    }),
  },
  DELETE_LEARNING_PATH_REQUEST: 'LEARNING_PATH/DELETE_LEARNING_PATH_REQUEST',
  DELETE_LEARNING_PATH_SUCCESS: 'LEARNING_PATH/DELETE_LEARNING_PATH_SUCCESS',
  DELETE_LEARNING_PATH_ERROR: 'LEARNING_PATH/DELETE_LEARNING_PATH_ERROR',
  DELETE_LEARNING_PATH_RESET: 'LEARNING_PATH/DELETE_LEARNING_PATH_RESET',
  deleteLearningPathAction: {
    request: (payload: DeleteLearningPathRequestAction) => ({
      type: learningPathActions.DELETE_LEARNING_PATH_REQUEST,
      payload,
    }),
    success: (payload: DeleteLearningPathSuccessAction) => ({
      type: learningPathActions.DELETE_LEARNING_PATH_SUCCESS,
      payload,
    }),
    error: (error: DeleteLearningPathErrorAction) => ({
      type: learningPathActions.DELETE_LEARNING_PATH_ERROR,
      error,
    }),
    reset: () => ({
      type: learningPathActions.DELETE_LEARNING_PATH_RESET,
    }),
  },

  FETCH_LEARNING_PATH_ALL_COURSE_LIST_REQUEST: 'LEARNING_PATH/FETCH_LEARNING_PATH_ALL_COURSE_LIST_REQUEST',
  FETCH_LEARNING_PATH_ALL_COURSE_LIST_SUCCESS: 'LEARNING_PATH/FETCH_LEARNING_PATH_ALL_COURSE_LIST_SUCCESS',
  FETCH_LEARNING_PATH_ALL_COURSE_LIST_ERROR: 'LEARNING_PATH/FETCH_LEARNING_PATH_ALL_COURSE_LIST_ERROR',
  FETCH_LEARNING_PATH_ALL_COURSE_LIST_RESET: 'LEARNING_PATH/FETCH_LEARNING_PATH_ALL_COURSE_LIST_RESET',
  fetchModuleCertificateAllCourseListAction: {
    request: (payload: FetchModuleCertificateAllCourseListRequestAction) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_ALL_COURSE_LIST_REQUEST,
      payload,
    }),
    success: (payload: FetchModuleCertificateAllCourseListSuccessAction) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_ALL_COURSE_LIST_SUCCESS,
      payload,
    }),
    error: (error: unknown) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_ALL_COURSE_LIST_ERROR,
      error,
    }),
    reset: () => ({
      type: learningPathActions.FETCH_LEARNING_PATH_ALL_COURSE_LIST_RESET,
    }),
  },

  LEARNING_PATH_CREATE_ERROR: 'LEARNING_PATH/LEARNING_PATH_CREATE_ERROR',
  LEARNING_PATH_CREATE_ERROR_UPDATE: 'LEARNING_PATH/LEARNING_PATH_CREATE_ERROR_UPDATE',
  LEARNING_PATH_CREATE_ERROR_RESET: 'LEARNING_PATH/LEARNING_PATH_CREATE_ERROR_RESET',
  moduleCertificateCreateErrorAction: {
    successState: (payload: ModuleCertificateCreateErrorState) => ({
      type: learningPathActions.LEARNING_PATH_CREATE_ERROR,
      payload,
    }),
    updateState: (payload: Partial<ModuleCertificateCreateErrorState>) => ({
      type: learningPathActions.LEARNING_PATH_CREATE_ERROR_UPDATE,
      payload,
    }),
    resetState: () => ({
      type: learningPathActions.LEARNING_PATH_CREATE_ERROR_RESET,
    }),
  },

  LEARNING_PATH_CREATE_PREVIEW_CERTIFICATE_REQUEST: 'LEARNING_PATH/LEARNING_PATH_CREATE_PREVIEW_CERTIFICATE_REQUEST',
  LEARNING_PATH_CREATE_PREVIEW_CERTIFICATE_SUCCESS: 'LEARNING_PATH/LEARNING_PATH_CREATE_PREVIEW_CERTIFICATE_SUCCESS',
  LEARNING_PATH_CREATE_PREVIEW_CERTIFICATE_ERROR: 'LEARNING_PATH/LEARNING_PATH_CREATE_PREVIEW_CERTIFICATE_ERROR',
  LEARNING_PATH_CREATE_PREVIEW_CERTIFICATE_RESET: 'LEARNING_PATH/LEARNING_PATH_CREATE_PREVIEW_CERTIFICATE_RESET',
  moduleCertificateCreatePreviewCertificateAction: {
    request: (payload: PreviewCertificateRequest) => ({
      type: learningPathActions.LEARNING_PATH_CREATE_PREVIEW_CERTIFICATE_REQUEST,
      payload,
    }),
    success: (payload: string) => ({
      type: learningPathActions.LEARNING_PATH_CREATE_PREVIEW_CERTIFICATE_SUCCESS,
      payload,
    }),
    error: (error: unknown) => ({
      type: learningPathActions.LEARNING_PATH_CREATE_PREVIEW_CERTIFICATE_ERROR,
      error,
    }),
    reset: () => ({
      type: learningPathActions.LEARNING_PATH_CREATE_PREVIEW_CERTIFICATE_RESET,
    }),
  },

  LEARNING_PATH_CREATE_SAVE_DRAFT_REQUEST: 'LEARNING_PATH/LEARNING_PATH_CREATE_SAVE_DRAFT_REQUEST',
  LEARNING_PATH_CREATE_SAVE_DRAFT_SUCCESS: 'LEARNING_PATH/LEARNING_PATH_CREATE_SAVE_DRAFT_SUCCESS',
  LEARNING_PATH_CREATE_SAVE_DRAFT_ERROR: 'LEARNING_PATH/LEARNING_PATH_CREATE_SAVE_DRAFT_ERROR',
  LEARNING_PATH_CREATE_SAVE_DRAFT_RESET: 'LEARNING_PATH/LEARNING_PATH_CREATE_SAVE_DRAFT_RESET',
  moduleCertificateCreateSaveDraftAction: {
    request: (payload: SaveModuleCertificateCreateRequest) => ({
      type: learningPathActions.LEARNING_PATH_CREATE_SAVE_DRAFT_REQUEST,
      payload,
    }),
    success: (payload: SaveStatus) => ({
      type: learningPathActions.LEARNING_PATH_CREATE_SAVE_DRAFT_SUCCESS,
      payload,
    }),
    error: (error: unknown) => ({
      type: learningPathActions.LEARNING_PATH_CREATE_SAVE_DRAFT_ERROR,
      error,
    }),
    reset: () => ({
      type: learningPathActions.LEARNING_PATH_CREATE_SAVE_DRAFT_RESET,
    }),
  },

  LEARNING_PATH_SAVE_PUBLISH_REQUEST: 'LEARNING_PATH/LEARNING_PATH_SAVE_PUBLISH_REQUEST',
  LEARNING_PATH_SAVE_PUBLISH_SUCCESS: 'LEARNING_PATH/LEARNING_PATH_SAVE_PUBLISH_SUCCESS',
  LEARNING_PATH_SAVE_PUBLISH_ERROR: 'LEARNING_PATH/LEARNING_PATH_SAVE_PUBLISH_ERROR',
  LEARNING_PATH_SAVE_PUBLISH_RESET: 'LEARNING_PATH/LEARNING_PATH_SAVE_PUBLISH_RESET',
  moduleCertificateSavePublishAction: {
    request: (payload: SaveModuleCertificateCreateRequest) => ({
      type: learningPathActions.LEARNING_PATH_SAVE_PUBLISH_REQUEST,
      payload,
    }),
    success: (payload: SaveStatus) => ({
      type: learningPathActions.LEARNING_PATH_SAVE_PUBLISH_SUCCESS,
      payload,
    }),
    error: (error: unknown) => ({
      type: learningPathActions.LEARNING_PATH_SAVE_PUBLISH_ERROR,
      error,
    }),
    reset: () => ({
      type: learningPathActions.LEARNING_PATH_SAVE_PUBLISH_RESET,
    }),
  },

  LEARNING_PATH_INSTRUCTOR_DROPDOWN_REQUEST: 'LEARNING_PATH/LEARNING_PATH_INSTRUCTOR_DROPDOWN_REQUEST',
  LEARNING_PATH_INSTRUCTOR_DROPDOWN_SUCCESS: 'LEARNING_PATH/LEARNING_PATH_INSTRUCTOR_DROPDOWN_SUCCESS',
  LEARNING_PATH_INSTRUCTOR_DROPDOWN_ERROR: 'LEARNING_PATH/LEARNING_PATH_INSTRUCTOR_DROPDOWN_ERROR',
  fetchModuleCertificateInstructorDropdownAction: {
    request: (payload: FetchInstructorDropdownRequestAction) => ({
      type: learningPathActions.LEARNING_PATH_INSTRUCTOR_DROPDOWN_REQUEST,
      payload,
    }),
    success: (payload: InstructorDropdownOption[]) => ({
      type: learningPathActions.LEARNING_PATH_INSTRUCTOR_DROPDOWN_SUCCESS,
      payload,
    }),
    error: (error: unknown) => ({
      type: learningPathActions.LEARNING_PATH_INSTRUCTOR_DROPDOWN_ERROR,
      error,
    }),
  },
  FETCH_LEARNING_PATH_STUDENT_LIST_REQUEST: 'LEARNING_PATH/FETCH_LEARNING_PATH_STUDENT_LIST_REQUEST',
  FETCH_LEARNING_PATH_STUDENT_LIST_SUCCESS: 'LEARNING_PATH/FETCH_LEARNING_PATH_STUDENT_LIST_SUCCESS',
  FETCH_LEARNING_PATH_STUDENT_LIST_ERROR: 'LEARNING_PATH/FETCH_LEARNING_PATH_STUDENT_LIST_ERROR',
  FETCH_LEARNING_PATH_STUDENT_LIST_RESET: 'LEARNING_PATH/FETCH_LEARNING_PATH_STUDENT_LIST_RESET',

  fetchLearningPathStudentListAction: {
    request: (payload: FetchLearningPathStudentListRequestAction) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_STUDENT_LIST_REQUEST,
      payload,
    }),
    success: (payload: FetchLearningPathStudentListSuccessAction) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_STUDENT_LIST_SUCCESS,
      payload,
    }),
    error: (error: FetchLearningPathStudentListErrorAction) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_STUDENT_LIST_ERROR,
      error,
    }),
    reset: () => ({
      type: learningPathActions.FETCH_LEARNING_PATH_STUDENT_LIST_RESET,
    }),
  },

  LEARNING_PATH_VIEW_DETAILS_CHANGE_STATUS_REQUEST: 'LEARNING_PATH/LEARNING_PATH_VIEW_DETAILS_CHANGE_STATUS_REQUEST',
  LEARNING_PATH_VIEW_DETAILS_CHANGE_STATUS_SUCCESS: 'LEARNING_PATH/LEARNING_PATH_VIEW_DETAILS_CHANGE_STATUS_SUCCESS',
  LEARNING_PATH_VIEW_DETAILS_CHANGE_STATUS_ERROR: 'LEARNING_PATH/LEARNING_PATH_VIEW_DETAILS_CHANGE_STATUS_ERROR',
  LEARNING_PATH_VIEW_DETAILS_CHANGE_STATUS_RESET: 'LEARNING_PATH/LEARNING_PATH_VIEW_DETAILS_CHANGE_STATUS_RESET',
  moduleCertificateViewDetailsChangeStatusAction: {
    request: (payload: LearningPathStatusRequest) => ({
      type: learningPathActions.LEARNING_PATH_VIEW_DETAILS_CHANGE_STATUS_REQUEST,
      payload,
    }),
    success: () => ({
      type: learningPathActions.LEARNING_PATH_VIEW_DETAILS_CHANGE_STATUS_SUCCESS,
    }),
    error: (error: unknown) => ({
      type: learningPathActions.LEARNING_PATH_VIEW_DETAILS_CHANGE_STATUS_ERROR,
      error,
    }),
    reset: () => ({
      type: learningPathActions.LEARNING_PATH_VIEW_DETAILS_CHANGE_STATUS_RESET,
    }),
  },

  LEARNING_PATH_SEARCH_SLUG_NAME_REQUEST: 'LEARNING_PATH/LEARNING_PATH_SEARCH_SLUG_NAME_REQUEST',
  LEARNING_PATH_SEARCH_SLUG_NAME_SUCCESS: 'LEARNING_PATH/LEARNING_PATH_SEARCH_SLUG_NAME_SUCCESS',
  LEARNING_PATH_SEARCH_SLUG_NAME_ERROR: 'LEARNING_PATH/LEARNING_PATH_SEARCH_SLUG_NAME_ERROR',
  moduleCertificateSearchSlugNameAction: {
    request: (payload: SearchCertificateBySlugRequest) => ({
      type: learningPathActions.LEARNING_PATH_SEARCH_SLUG_NAME_REQUEST,
      payload,
    }),
    success: (payload: string) => ({
      type: learningPathActions.LEARNING_PATH_SEARCH_SLUG_NAME_SUCCESS,
      payload,
    }),
    error: (error: unknown) => ({
      type: learningPathActions.LEARNING_PATH_SEARCH_SLUG_NAME_ERROR,
      error,
    }),
  },

  UPLOAD_LEARNING_PATH_CARD_REQUEST: 'LEARNING_PATH/UPLOAD_LEARNING_PATH_CARD_REQUEST',
  UPLOAD_LEARNING_PATH_CARD_SUCCESS: 'LEARNING_PATH/UPLOAD_LEARNING_PATH_CARD_SUCCESS',
  UPLOAD_LEARNING_PATH_CARD_ERROR: 'LEARNING_PATH/UPLOAD_LEARNING_PATH_CARD_ERROR',
  UPLOAD_LEARNING_PATH_CARD_RESET: 'LEARNING_PATH/UPLOAD_LEARNING_PATH_CARD_RESET',
  uploadLearningPathCardAction: {
    request: (payload: UploadLearningPathImageRequest) => ({
      type: learningPathActions.UPLOAD_LEARNING_PATH_CARD_REQUEST,
      payload,
    }),
    success: (payload: UploadLearningPathImageResponse) => ({
      type: learningPathActions.UPLOAD_LEARNING_PATH_CARD_SUCCESS,
      payload,
    }),
    error: (error: unknown) => ({
      type: learningPathActions.UPLOAD_LEARNING_PATH_CARD_ERROR,
      error,
    }),
    reset: () => ({
      type: learningPathActions.UPLOAD_LEARNING_PATH_CARD_RESET,
    }),
  },

  UPLOAD_LEARNING_PATH_BANNER_COVER_REQUEST: 'LEARNING_PATH/UPLOAD_LEARNING_PATH_BANNER_COVER_REQUEST',
  UPLOAD_LEARNING_PATH_BANNER_COVER_SUCCESS: 'LEARNING_PATH/UPLOAD_LEARNING_PATH_BANNER_COVER_SUCCESS',
  UPLOAD_LEARNING_PATH_BANNER_COVER_ERROR: 'LEARNING_PATH/UPLOAD_LEARNING_PATH_BANNER_COVER_ERROR',
  UPLOAD_LEARNING_PATH_BANNER_COVER_RESET: 'LEARNING_PATH/UPLOAD_LEARNING_PATH_BANNER_COVER_RESET',
  uploadLearningPathBannerCoverAction: {
    request: (payload: UploadLearningPathImageRequest) => ({
      type: learningPathActions.UPLOAD_LEARNING_PATH_BANNER_COVER_REQUEST,
      payload,
    }),
    success: (payload: UploadLearningPathImageResponse) => ({
      type: learningPathActions.UPLOAD_LEARNING_PATH_BANNER_COVER_SUCCESS,
      payload,
    }),
    error: (error: unknown) => ({
      type: learningPathActions.UPLOAD_LEARNING_PATH_BANNER_COVER_ERROR,
      error,
    }),
    reset: () => ({
      type: learningPathActions.UPLOAD_LEARNING_PATH_BANNER_COVER_RESET,
    }),
  },

  UPLOAD_LEARNING_PATH_LINE_IMAGE_REQUEST: 'LEARNING_PATH/UPLOAD_LEARNING_PATH_LINE_IMAGE_REQUEST',
  UPLOAD_LEARNING_PATH_LINE_IMAGE_SUCCESS: 'LEARNING_PATH/UPLOAD_LEARNING_PATH_LINE_IMAGE_SUCCESS',
  UPLOAD_LEARNING_PATH_LINE_IMAGE_ERROR: 'LEARNING_PATH/UPLOAD_LEARNING_PATH_LINE_IMAGE_ERROR',
  UPLOAD_LEARNING_PATH_LINE_IMAGE_RESET: 'LEARNING_PATH/UPLOAD_LEARNING_PATH_LINE_IMAGE_RESET',
  uploadLearningPathLineImageAction: {
    request: (payload: UploadLearningPathImageRequest) => ({
      type: learningPathActions.UPLOAD_LEARNING_PATH_LINE_IMAGE_REQUEST,
      payload,
    }),
    success: (payload: UploadLearningPathImageResponse) => ({
      type: learningPathActions.UPLOAD_LEARNING_PATH_LINE_IMAGE_SUCCESS,
      payload,
    }),
    error: (error: unknown) => ({
      type: learningPathActions.UPLOAD_LEARNING_PATH_LINE_IMAGE_ERROR,
      error,
    }),
    reset: () => ({
      type: learningPathActions.UPLOAD_LEARNING_PATH_LINE_IMAGE_RESET,
    }),
  },

  UPLOAD_LEARNING_PATH_FACEBOOK_IMAGE_REQUEST: 'LEARNING_PATH/UPLOAD_LEARNING_PATH_FACEBOOK_IMAGE_REQUEST',
  UPLOAD_LEARNING_PATH_FACEBOOK_IMAGE_SUCCESS: 'LEARNING_PATH/UPLOAD_LEARNING_PATH_FACEBOOK_IMAGE_SUCCESS',
  UPLOAD_LEARNING_PATH_FACEBOOK_IMAGE_ERROR: 'LEARNING_PATH/UPLOAD_LEARNING_PATH_FACEBOOK_IMAGE_ERROR',
  UPLOAD_LEARNING_PATH_FACEBOOK_IMAGE_RESET: 'LEARNING_PATH/UPLOAD_LEARNING_PATH_FACEBOOK_IMAGE_RESET',
  uploadLearningPathFacebookImageAction: {
    request: (payload: UploadLearningPathImageRequest) => ({
      type: learningPathActions.UPLOAD_LEARNING_PATH_FACEBOOK_IMAGE_REQUEST,
      payload,
    }),
    success: (payload: UploadLearningPathImageResponse) => ({
      type: learningPathActions.UPLOAD_LEARNING_PATH_FACEBOOK_IMAGE_SUCCESS,
      payload,
    }),
    error: (error: unknown) => ({
      type: learningPathActions.UPLOAD_LEARNING_PATH_FACEBOOK_IMAGE_ERROR,
      error,
    }),
    reset: () => ({
      type: learningPathActions.UPLOAD_LEARNING_PATH_FACEBOOK_IMAGE_RESET,
    }),
  },

  LEARNING_PATH_SECTION_ITEM_LIST_REQUEST: 'LEARNING_PATH/LEARNING_PATH_SECTION_ITEM_LIST_REQUEST',
  LEARNING_PATH_SECTION_ITEM_LIST_SUCCESS: 'LEARNING_PATH/LEARNING_PATH_SECTION_ITEM_LIST_SUCCESS',
  LEARNING_PATH_SECTION_ITEM_LIST_UPDATE: 'LEARNING_PATH/LEARNING_PATH_SECTION_ITEM_LIST_UPDATE',
  LEARNING_PATH_SECTION_ITEM_LIST_ERROR: 'LEARNING_PATH/LEARNING_PATH_SECTION_ITEM_LIST_ERROR',
  fetchLearningPathSectionItemListAction: {
    request: (payload: FetchLearningPathListRequestAction) => ({
      type: learningPathActions.LEARNING_PATH_SECTION_ITEM_LIST_REQUEST,
      payload,
    }),
    success: (payload: LearningPathEntity[]) => ({
      type: learningPathActions.LEARNING_PATH_SECTION_ITEM_LIST_SUCCESS,
      payload,
    }),
    error: (error: unknown) => ({
      type: learningPathActions.LEARNING_PATH_SECTION_ITEM_LIST_ERROR,
      error,
    }),
  },
  FETCH_LEARNING_PATH_COURSE_BUNDLE_LIST_REQUEST: 'LEARNING_PATH/FETCH_LEARNING_PATH_COURSE_BUNDLE_LIST_REQUEST',
  FETCH_LEARNING_PATH_COURSE_BUNDLE_LIST_SUCCESS: 'LEARNING_PATH/FETCH_LEARNING_PATH_COURSE_BUNDLE_LIST_SUCCESS',
  FETCH_LEARNING_PATH_COURSE_BUNDLE_LIST_ERROR: 'LEARNING_PATH/FETCH_LEARNING_PATH_COURSE_BUNDLE_LIST_ERROR',
  FETCH_LEARNING_PATH_COURSE_BUNDLE_LIST_RESET: 'LEARNING_PATH/FETCH_LEARNING_PATH_COURSE_BUNDLE_LIST_RESET',
  fetchLearningPathCourseBundleListAction: {
    request: (payload: FetchLearningPathCourseBundleListRequestAction) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_COURSE_BUNDLE_LIST_REQUEST,
      payload,
    }),
    success: (payload: FetchLearningPathCourseBundleListSuccessAction) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_COURSE_BUNDLE_LIST_SUCCESS,
      payload,
    }),
    error: (error: unknown) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_COURSE_BUNDLE_LIST_ERROR,
      error,
    }),
    reset: () => ({
      type: learningPathActions.FETCH_LEARNING_PATH_COURSE_BUNDLE_LIST_RESET,
    }),
  },

  FETCH_LEARNING_PATH_DETAIL_REQUEST: 'user/FETCH_LEARNING_PATH_DETAIL_REQUEST',
  FETCH_LEARNING_PATH_DETAIL_SUCCESS: 'user/FETCH_LEARNING_PATH_DETAIL_SUCCESS',
  FETCH_LEARNING_PATH_DETAIL_ERROR: 'user/FETCH_LEARNING_PATH_DETAIL_ERROR',
  FETCH_LEARNING_PATH_DETAIL_RESET: 'user/FETCH_LEARNING_PATH_DETAIL_RESET',
  fetchLearningPathDetailAction: {
    request: (payload: FetchLearningPathDetailRequestAction) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_DETAIL_REQUEST,
      payload,
    }),
    success: (payload: FetchLearningPathDetailSuccessAction) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_DETAIL_SUCCESS,
      payload,
    }),
    error: (payload: FetchLearningPathDetailErrorAction) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_DETAIL_ERROR,
      payload,
    }),
    reset: () => ({
      type: learningPathActions.FETCH_LEARNING_PATH_DETAIL_RESET,
    }),
  },

  FETCH_LEARNING_PATH_DETAIL_SEO_REQUEST: 'user/FETCH_LEARNING_PATH_DETAIL_SEO_REQUEST',
  FETCH_LEARNING_PATH_DETAIL_SEO_SUCCESS: 'user/FETCH_LEARNING_PATH_DETAIL_SEO_SUCCESS',
  FETCH_LEARNING_PATH_DETAIL_SEO_ERROR: 'user/FETCH_LEARNING_PATH_DETAIL_SEO_ERROR',
  FETCH_LEARNING_PATH_DETAIL_SEO_RESET: 'user/FETCH_LEARNING_PATH_DETAIL_SEO_RESET',
  fetchLearningPathDetailSeoAction: {
    request: (payload: FetchLearningPathDetailSeoRequestAction) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_DETAIL_SEO_REQUEST,
      payload,
    }),
    success: (payload: FetchLearningPathDetailSeoSuccessAction) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_DETAIL_SEO_SUCCESS,
      payload,
    }),
    error: (payload: FetchLearningPathDetailSeoErrorAction) => ({
      type: learningPathActions.FETCH_LEARNING_PATH_DETAIL_SEO_ERROR,
      payload,
    }),
    reset: () => ({
      type: learningPathActions.FETCH_LEARNING_PATH_DETAIL_SEO_RESET,
    }),
  },

  CREATE_LEARNING_PATH_SHARE_LOG_REQUEST: 'user/CREATE_LEARNING_PATH_SHARE_LOG_REQUEST',
  CREATE_LEARNING_PATH_SHARE_LOG_SUCCESS: 'user/CREATE_LEARNING_PATH_SHARE_LOG_SUCCESS',
  CREATE_LEARNING_PATH_SHARE_LOG_ERROR: 'user/CREATE_LEARNING_PATH_SHARE_LOG_ERROR',
  CREATE_LEARNING_PATH_SHARE_LOG_RESET: 'user/CREATE_LEARNING_PATH_SHARE_LOG_RESET',
  createLearningPathShareLogAction: {
    request: (payload: CreateLearningPathShareLogRequestAction) => ({
      type: learningPathActions.CREATE_LEARNING_PATH_SHARE_LOG_REQUEST,
      payload,
    }),
    success: (payload: CreateLearningPathShareLogSuccessAction) => ({
      type: learningPathActions.CREATE_LEARNING_PATH_SHARE_LOG_SUCCESS,
      payload,
    }),
    error: (payload: CreateLearningPathShareLogErrorAction) => ({
      type: learningPathActions.CREATE_LEARNING_PATH_SHARE_LOG_ERROR,
      payload,
    }),
    reset: () => ({
      type: learningPathActions.CREATE_LEARNING_PATH_SHARE_LOG_RESET,
    }),
  },

  FETCH_MODULE_CERTIFICATE_REQUEST: 'user/FETCH_MY_MODULE_CERTIFICATE_REQUEST',
  FETCH_MODULE_CERTIFICATE_SUCCESS: 'user/FETCH_MODULE_CERTIFICATE_SUCCESS',
  FETCH_MODULE_CERTIFICATE_ERROR: 'user/FETCH_MODULE_CERTIFICATE_ERROR',
  FETCH_MODULE_CERTIFICATE_RESET: 'user/FETCH_MODULE_CERTIFICATE_RESET',
  fetchModuleCertificateAction: {
    request: (payload: FetchModuleCertificateRequestAction) => ({
      type: learningPathActions.FETCH_MODULE_CERTIFICATE_REQUEST,
      payload,
    }),
    success: (payload: FetchModuleCertificateSuccessAction) => ({
      type: learningPathActions.FETCH_MODULE_CERTIFICATE_SUCCESS,
      payload,
    }),
    error: (payload: FetchModuleCertificateErrorAction) => ({
      type: learningPathActions.FETCH_MODULE_CERTIFICATE_ERROR,
      payload,
    }),
    reset: () => ({
      type: learningPathActions.FETCH_MODULE_CERTIFICATE_RESET,
    }),
  },

  FETCH_MY_LEARNING_PATH_REQUEST: 'user/FETCH_MY_LEARNING_PATH_REQUEST',
  FETCH_MY_LEARNING_PATH_SUCCESS: 'user/FETCH_MY_LEARNING_PATH_SUCCESS',
  FETCH_MY_LEARNING_PATH_ERROR: 'user/FETCH_MY_LEARNING_PATH_ERROR',
  FETCH_MY_LEARNING_PATH_RESET: 'user/FETCH_MY_LEARNING_PATH_RESET',
  fetchMyLearningPathAction: {
    request: (payload: FetchMyLearningPathRequestAction) => ({
      type: learningPathActions.FETCH_MY_LEARNING_PATH_REQUEST,
      payload,
    }),
    success: (payload: FetchMyLearningPathSuccessAction) => ({
      type: learningPathActions.FETCH_MY_LEARNING_PATH_SUCCESS,
      payload,
    }),
    error: (payload: FetchMyLearningPathErrorAction) => ({
      type: learningPathActions.FETCH_MY_LEARNING_PATH_ERROR,
      payload,
    }),
    reset: () => ({
      type: learningPathActions.FETCH_MY_LEARNING_PATH_RESET,
    }),
  },
};

export default learningPathActions;
